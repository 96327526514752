// Array asociativo de idiomas y su texto
var idiomasEnlacesExternos = {'es':'Este enlace se abre en una nueva pantalla', 'en':'This link opens in a new screen', 'ca':'Aquest enlla&ccedil; s\'obre en una nova pantalla', 'gl':'Esta ligaz&oacute;n &aacute;brese nunha nova pantalla', 'eu':'Esteka hau beste pantaila batean irekiko da', 'fr':'Ce lien s\'ouvre dans une nouvelle fen&ecirc;tre', 'va':'Aquest enlla&ccedil; s\'obre en una nova pantalla', 'fr':'Ce lien s\'ouvre dans une nouvelle fen&ecirc;tre'};
var codigoIdioma;

$(document).ready(function() {
	$divCreta1= $("#apdo_1288785102945 div.tipoTramitacion");
	$divCreta2= $("#apdo_1288788118101 div.tipoTramitacion");
	
	if($divCreta1.length == 1 && $divCreta2.length == 1){
		codigoIdioma = $('html').attr('lang');
		var popup='';
		switch (codigoIdioma) { 
			case 'es': 
				popup = '<div class="enlaceCreta"><a class="avisoCreta" data-modal="avisoCreta" data-txt="&lt;strong&gt;Configuraciones recomendadas&lt;/strong&gt;&lt;br/&gt;		&lt;ul style=\'margin: 5px 0px 0px 90px;overflow:auto\'&gt;			&lt;li style=\'float:left\'&gt;&lt;u&gt;Sistemas operativos&lt;/u&gt;: Windows7 y Windows10 &lt;/li&gt;			&lt;li style=\'float:left\'&gt;&lt;u&gt;Navegadores&lt;/u&gt;: IExplorer 11, Chrome 54, Firefox 52, Edge 38&lt;/li&gt;		&lt;/ul&gt;" data-txt-enlace="Aceptar" href="#">Comprueba si tu equipo cumple con los requisitos para realizar el tr&aacute;mite</a></div>';
				break;
			case 'en': 
				popup = '<div class="enlaceCreta"><a class="avisoCreta" data-modal="avisoCreta" data-txt="&lt;strong&gt;Setting recommended&lt;/strong&gt;&lt;br/&gt;		&lt;ul style=\'margin: 5px 0px 0px 90px;overflow:auto\'&gt;			&lt;li style=\'float:left\'&gt;&lt;u&gt;Operating system&lt;/u&gt;: Windows7 y Windows10 &lt;/li&gt;			&lt;li style=\'float:left\'&gt;&lt;u&gt;Browsers&lt;/u&gt;: IExplorer 11, Chrome 54, Firefox 52, Edge 38&lt;/li&gt;		&lt;/ul&gt;" data-txt-enlace="Accept" href="#">Check that your computer meets the requirements to carry out the procedure</a></div>';
				break;
			case 'ca': 
				popup = '<div class="enlaceCreta"><a class="avisoCreta" data-modal="avisoCreta" data-txt="&lt;strong&gt;Configuraci&oacute; recomanada&lt;/strong&gt;&lt;br/&gt;		&lt;ul style=\'margin: 5px 0px 0px 90px;overflow:auto\'&gt;			&lt;li style=\'float:left\'&gt;&lt;u&gt;Sistema operatiu&lt;/u&gt;: Windows7 y Windows10 &lt;/li&gt;			&lt;li style=\'float:left\'&gt;&lt;u&gt;Navegadors&lt;/u&gt;: IExplorer 11, Chrome 54, Firefox 52, Edge 38&lt;/li&gt;		&lt;/ul&gt;" data-txt-enlace="Acceptar" href="#">Comproveu si el vostre equip compleix els requisits per fer el tr&agrave;mit</a></div>';
				break;		
			case 'ga': 
				popup = '<div class="enlaceCreta"><a class="avisoCreta" data-modal="avisoCreta" data-txt="&lt;strong&gt;Configuraciones recomendadas&lt;/strong&gt;&lt;br/&gt;		&lt;ul style=\'margin: 5px 0px 0px 90px;overflow:auto\'&gt;			&lt;li style=\'float:left\'&gt;&lt;u&gt;Sistemas operativos&lt;/u&gt;: Windows7 y Windows10 &lt;/li&gt;			&lt;li style=\'float:left\'&gt;&lt;u&gt;Navegadores&lt;/u&gt;: IExplorer 11, Chrome 54, Firefox 52, Edge 38&lt;/li&gt;		&lt;/ul&gt;" data-txt-enlace="Aceptar" href="#">Comprueba si tu equipo cumple con los requisitos para realizar el tr&aacute;mite</a></div>';
				break;
			default:
				popup = '<div class="enlaceCreta"><a class="avisoCreta" data-modal="avisoCreta" data-txt="&lt;strong&gt;Configuraciones recomendadas&lt;/strong&gt;&lt;br/&gt;		&lt;ul style=\'margin: 5px 0px 0px 90px;overflow:auto\'&gt;			&lt;li style=\'float:left\'&gt;&lt;u&gt;Sistemas operativos&lt;/u&gt;: Windows7 y Windows10 &lt;/li&gt;			&lt;li style=\'float:left\'&gt;&lt;u&gt;Navegadores&lt;/u&gt;: IExplorer 11, Chrome 54, Firefox 52, Edge 38&lt;/li&gt;		&lt;/ul&gt;" data-txt-enlace="Aceptar" href="#">Comprueba si tu equipo cumple con los requisitos para realizar el tr&aacute;mite</a></div>';
		}
		$divCreta1.after(popup);
		$divCreta2.after(popup);
	}
});

$(function(){
//	console.info('jQuery.fn.jquery ' + jQuery.fn.jquery) // 1.5.2 - 1.11.1
	var verJq =  jQuery.fn.jquery;

	$(window).bind("load resize",function(e){
		width_window = $(window).width() 
		height_window = $(window).height();

		var difer_width = 17; // diferencia entra el width de css y $(window).width()
		$('html').attr("data-dispo","")

		// desktop
		if(width_window>991-difer_width){
			$('html').attr("data-dispo","desktop")	
			menuDesktop()
		}

		// tablet
		if( (width_window<991-difer_width) && (width_window>500-difer_width) ) {	
			$('html').attr("data-dispo","tablet")	
		}

		// movil
		if(width_window<501-difer_width){			
			$('html').attr("data-dispo","movil")
			menuNoDesktop()	
			listadosNoDesktop()			
		}
	})

	    // funciones para desktop
    function check_images(width_window){
		$('img').each(function(index, el) {
			if($(this).width() >width_window ){
				$(this).css({"width":"100%","height":"auto"})
			}
		});
	}

	// funciones para menu lateral NO desktop
	function menuNoDesktop(){
		$('#menuPrincipal').attr('id','menuPrincipalNoDesktop')
	}

	// funciones para menu lateral NO desktop
	function menuDesktop(){
        $('#menuPrincipalNoDesktop').attr('id','menuPrincipal') 
	}	

	// quita el padding de los <li> con <a> porque los <li> vacios ya tienen padding y se lo ponen en los <li> sin enlace
	function listadosNoDesktop(){
		$('.contenido li,#contenido li').each(function(index, el) {
			$(this).attr('style', 'padding: 10px !important');
		});

		$('.contenido,#contenido').not( ".home" ).find('li').each(function(index, el) {
			$(this).find('a').closest( "li" ).attr('style', 'padding: 0 !important');
		});
	}	

/* ---------------------------------	FIN MOVIL ---------------------------------- */
	$('#actualizarFecha').hide() 
	
	if(!$('#bloqueBannersInstitucionales').length){
		$('footer').addClass('')
	}

	if($('#home').length){
		/* check noticias_destacadas contenido */
		if ($('.noticias_destacadas').children().length){
				$('.bloque_noticias_destacadas').addClass('contenido')   
		}
	}

	 var accentMap = {
        "�": "a",
        "�": "a",
        "�": "a",
        "�": "a",
        "�": "a",
        "�": "c",
        "�": "e",
        "�": "e",
        "�": "e",
        "�": "e",
        "�": "e",
        "�": "i",
        "�": "i",
        "�": "e",
        "�": "i",
        "�": "i",
        "�": "o",
        "�": "o",
        "�": "o",
        "�": "o",
        "�": "o",
        "�": "u",
        "�": "u",
        "�": "u",
        "�": "u",
        "�": "u"            
	 };
    var normalize = function( term ) {
	    var ret = "";
	    for ( var i = 0; i < term.length; i++ ) {
	        ret += accentMap[ term.charAt(i) ] || term.charAt(i);
	    }

	    return ret;
    };

	/* menu idiomas cabecera */
	$('#menuIdiomas').addClass('js')
	var idioma = $('#menuIdiomas strong').parent()
	$('#menuIdiomas ul').prepend(idioma)
	$('#menuIdiomas').mouseover(function(){$(this).addClass('visible') })
	$('#menuIdiomas').mouseleave(function(){$(this).removeClass('visible')	})
		
	// buscador paginas internas
	if($('#bloqueBuscador').length){
		$('#bloqueBuscador #buscador').focus(function(){
			$(this).addClass('focus').attr('placeholder', '');
		})
	}

	// check pagina tramites
	if($("#main_res").length){

		// tramites buscador filtros y textos
		$('.bloque_titulo').html($('#main_res #tituloResultados'))
		$('#todos_tramites').appendTo('.bloque_titulo')
		$('#mostrar_filtros_buscador').show().appendTo('.buscador_filtro')
		$( ".bloque_filtro" ).wrapAll( "<div class='bloque_filtros_todos' />");
	
		// mostrar los iconos de mas-menos por accesibilidad
		$('#dyn_nav div.enlace_mas_menos').show()

		// si hay filtros ya escogidos en el buscador
		if( $('#dyn_nav .enlaces_filtro span.dn-overflow').length){
			$('.bloque_filtros_todos').addClass('bloque_filtros_todos_on')
			$('#mostrar_filtros_buscador').attr('class','filtros_on' )
			texto_filtros_ocultar=$('#mostrar_filtros_buscador').attr('data-filtros-ocultar' )
			$('#mostrar_filtros_buscador').html(texto_filtros_ocultar)
		}

		// ocultar filtros y cambiar txt
		$('#mostrar_filtros_buscador').click(function(){
			$(this).toggleClass('filtros_off' )
			$('.bloque_filtros_todos').toggleClass('bloque_filtros_todos_on' )
				
			if($(this).hasClass('filtros_off')){
				$(this).html($(this).attr('data-filtros-mostrar'))					
			}else{				
				$(this).html($(this).attr('data-filtros-ocultar'))					
			}
			return false;
		})

		// convertimos toda la caja de cada tramite en enlace a su detalle
		$("#main_res .g ").each(function(index, el) {
			var enlace = $(this).find('a').attr('href')
			$(this).wrap('<a href="'+enlace+'" class="bloqueEnlaceTramite"></div>')
		});

		// en las cajas del litado de tramites y su detalle que solo sean informativas hay que eliminar el aspecto de enlace
		$("#main_res .bloqueInfoTramite").mouseover(function() {
				$(this).find('.tipoTramitacion').css('display','block')
				$(this).find('.enlaceCreta').css('display','block') 
		})

		$("#main_res .bloqueInfoTramite").mouseout(function() {				
				$(this).find('.tipoTramitacion').css('display','none')
				$(this).find('.enlaceCreta').css('display','none')
		})

	} // if($("#main_res").length)

	if($("#criteriosBusqueda").length && !$("#main_res").length){
		$("#criteriosBusqueda").css('padding', '2em');
	}

	if($("#detalleTramite").length){
		if($("#contenidoLateral").length){				
			$("#detalleTramiteContenido").addClass("detalleTramiteContenidoBanners");
		}

		// icos de ayuda 
		var enlace_ayuda_clave=$('#Enlace_C-sd_enl_clave a').attr('href')
		if(enlace_ayuda_clave!=undefined){
			var title_ayuda_clave=$('#Enlace_C-sd_enl_clave a').text()
			$('.certificado_clave').parent().append("<div class='tipoInfoTramiteAyuda'><a href='"+enlace_ayuda_clave+"' title='"+title_ayuda_clave+"'><img src='https://sede.mjusticia.gob.es/estatico/cs/sede/img/ico_ayuda_nuevo.png'></a></div>")				
			$('.con_certificado.bloqueClave').parent().append("<div class='tipoInfoTramiteAyuda'><a href='"+enlace_ayuda_clave+"' title='"+title_ayuda_clave+"'><img src='https://sede.mjusticia.gob.es/estatico/cs/sede/img/ico_ayuda_nuevo.png'></a></div>")				
		}

		var enlace_ayuda_cert_digital=$('#SDInformacion-sd_tramitacioncertif a').attr('href')
		if(enlace_ayuda_cert_digital!=undefined){
			var title_ayuda_cert_digital=$('#SDInformacion-sd_tramitacioncertif a').text()
			$('.bloqueDnie').parent().append("<div class='tipoInfoTramiteAyuda'><a href='"+enlace_ayuda_cert_digital+"' title='"+title_ayuda_cert_digital+"'><img src='https://sede.mjusticia.gob.es/estatico/cs/sede/img/ico_ayuda_nuevo.png'></a></div>")
		}
			
		var enlace_ayuda_tramita_completa=$('#SDInformacion-sd_tramitaciontelema a').attr('href')
		if(enlace_ayuda_tramita_completa!=undefined){
			var title_ayuda_tramita_completa=$('#SDInformacion-sd_tramitaciontelema a').text()
			$('.tipoTramitacion.TC').append("<div class='tipoTramitacionAyuda'><a href='"+enlace_ayuda_tramita_completa+"' title='"+title_ayuda_tramita_completa+"'><img src='https://sede.mjusticia.gob.es/estatico/cs/sede/img/ico_ayuda_nuevo.png'></a></div>")
		}

		var enlace_ayuda_tramita_parcial=$('#SDInformacion-sd_tramitacionparcia a').attr('href')
		if(enlace_ayuda_tramita_parcial!=undefined){
			var title_ayuda_tramita_parcial=$('#SDInformacion-sd_tramitacionparcia a').text()
			$('.tipoTramitacion.TP').append("<div class='tipoTramitacionAyuda'><a href='"+enlace_ayuda_tramita_parcial+"' title='"+title_ayuda_tramita_parcial+"'><img src='https://sede.mjusticia.gob.es/estatico/cs/sede/img/ico_ayuda_nuevo.png'></a></div>")			
		}

		bloques_tramites_alto=0;
		$('.bloqueInfoTramite').each(function(){
			bloques_tramites_alto+= $(this).height()
		})

		poner_fondo_detalle_tramite()			

		$('.masInfoTramite').click(function(){
			poner_fondo_detalle_tramite()
		})

		// check if bloque encuesta esta dentro del bloque_tramites para sacarlo fuera
		if( $(".ico_encuesta",".bloques_detalle_tramites").length ){
			$(".ico_encuesta").insertAfter('.bloques_detalle_tramites')
		}
			
		// check if bloque dudas esta dentro del bloque_tramites para sacarlo fuera
		if( $(".ico_dudas",".bloques_detalle_tramites").length ){
			$(".ico_dudas").insertAfter('.bloques_detalle_tramites')
		}
			
		// ie8
		if ($('html').hasClass('no-opacity')) {
			$('#tramites.tramites #contenido').addClass('tramite_ie8')
		}			
	} // if($("#detalleTramite").length)

	var bloques_detalle_tramites_alto = 0;
	function poner_fondo_detalle_tramite(){
		if($('.bloques_detalle_tramites').length){
			bloques_detalle_tramites_alto=$('.bloques_detalle_tramites').height()
			bloques_detalle_tramites_alto+=40
		}else{
			$('#tramites.tramites #contenido').css('background-size','0px')
		}
			
		if( $('#detalleTramite .aviso_tecnico').length ){
			alto_aviso = $('#detalleTramite .aviso_tecnico').height()
			bloques_detalle_tramites_alto+=alto_aviso+60
		}

		$('#tramites.tramites #contenido').css('background-size','1px ' + bloques_detalle_tramites_alto + 'px')
	}

	$('.listaUtilidadesCompartir').hide()
		
	// Inicia la fecha y la hora
	// Reloj();
		
	// Comprobamos que hay enlaces externos y a�adimos el atributo target y el texto que indica que se abre una ventan nueva segun el idioma establecido en el html
	if($('.enlacePantallaNueva').length){			
		idiomaPagina()				
	}
		
	// Add ARIA roles to the document
	addARIARole('cabecera', 'banner'); 
	addARIARole('contenidoHome', 'main');
	addARIARole('contenidoSeccion', 'main');
	addARIARole('menu', 'navigation');			
	addARIARole('contenidoLateral', 'complementary');	
	addARIARole('pie', 'contentinfo');	
	addARIARole('bloqueBuscador', 'search');

	$('.ayudaTextoMostrar').mouseover(function(){
		$('.ayudaTexto').removeClass('oculto')
	})

	$('.ayudaTextoMostrar').mouseout(function(){
		$('.ayudaTexto').addClass('oculto')
	})		
		
	$('.enlaceAviso').click(function(){ 
			texto=$(this).attr('title');
			var dato=confirm(texto,"");
			if (dato!=true)return false;	
	});

	// en este enlace aviso el title lo coge siempre del logo
	$('.enlaceAviso2').click(function(){ 
			texto=$('#cabeceraIzq .enlaceAviso').attr('title');				
			var dato=confirm(texto,"");
			if (dato!=true)return false;	
	});

	// Oculta el contenido cuando se tiene js habilitado
	$('.ocultarConJS').hide();

	// enlace para denegar el permiso para el tratamiento estad�stico de los datos o la informaci�n con Google Analytics
	if( $('#denegar_tratamiento_analytics').length){		
		$('#denegar_tratamiento_analytics').click(function(){
			$.cookie('analytics', 'false', { expires: 728, path: '/'});
			var txt_denegar = $('#texto_cookies').text()
			alert(txt_denegar)
			return false;
		})				
	}

	if($('#formularioEmail').length){
		var mapa="<iframe width='503' scrolling='no' height='380' frameborder='0' name='Callejero' title='Callejero' marginheight='0' marginwidth='0' src='https://maps.google.es/maps?f=q&amp;source=s_q&amp;hl=es&amp;q=Calle+Bolsa,+8,+28012+Madrid,+Comunidad+de+Madrid&amp;sll=40.396764,-3.713379&amp;sspn=9.769253,16.940918&amp;ie=UTF8&amp;cd=1&amp;geocode=FbasaAIduXvH_w&amp;split=0&amp;ll=40.416567,-3.702929&amp;spn=0.005718,0.008047&amp;z=16&amp;iwloc=A&amp;output=embed'></iframe> ";
		$('#contactoAtencionPresencialMapa').html(mapa);

		$('#Comentario').focus(function(){
			$(this).css({'height':'6em'})
		})

		$('.enlaceCaptcha').show()
	}

	// check tramite con pantalla de listado de entidades bancarias para fix bug en ie8
	if($('#dialogTarjeta').length){
		// check ie8
		if ($('html').hasClass('no-opacity')) {		
			setTimeout(function(){
				$('.site').siblings('div').hide()
			},3000)				
		}
	}

	// crear enlaces subir - preguntas frecuentes - resuelva dudas
	if($('p a.subir').length){
		var $subir = $('.subir').parent().html();
		$('.titulo_09sb').each(function( index,value ) {				
			$( this ).next().append("<p>"+$subir+"</p>")
		});	
		$('.subir:last').remove()	
	}
}) // Fin del function() de jquery

/* insertar enlace de imprimir en el bloque de utilidades web 2.0 cuando haya js */
function imprimirUtilidad(textoImprimir){
	$('.bloqueUtilidadesWeb ul li:first').append( '<a href="#" class="imprimirUtilidadWeb">'+ textoImprimir +'</a>')
	$('.imprimirUtilidadWeb').click(function(){ window.print(); return false })
}
			
/* insertar enlace de compartir en el bloque de utilidades web 2.0 cuando haya js */
function compartirUtilidad(textoCompartir){
		 
	$('.compartirEnlaces').append( '<a href="#" class="compartirUtilidadWeb">'+ textoCompartir +'</a>')
	$('.compartirUtilidadWeb').toggle(
		function () {
			$(this).css({fontWeight:'bold'})
			$('.listaUtilidadesCompartir').fadeIn() 					
		},
		function () {
			$(this).css({fontWeight:'normal'})
			$('.listaUtilidadesCompartir').fadeOut() 
		}			 
	)		    
}
			
// Extrae el idioma de la pagina y llama a la funcion que carga el texto en ese idioma
function idiomaPagina(){
	codigoIdioma = $('html').attr('lang')	
				
	for (var codigoPais in idiomasEnlacesExternos){
		if(codigoPais==codigoIdioma){
			crearEnlaceExterno(idiomasEnlacesExternos[codigoPais])
		}		
	}				
}
			
/* muestra de forma oculta el texto que indica que el enlace se abre en pantalla nueva y lo abre en otra pantalla */
function crearEnlaceExterno(textoEnlaceExterno){	
			$('.enlacePantallaNueva').attr('target','blank')
			$('.enlacePantallaNueva').append('<span class="oculto">'+ textoEnlaceExterno +'</span>')	
}
					
// Funciona que valida los campos del formulario de la pagina 'Enviar noticia a un amigo'
function validaEmail(regexp, value1, error1, value2, error2){
	var value1correcto = false;
	var value2correcto = false;
	
	var mensajeerror = '';
	
	if (value1!="" && value1!=null && regexp.test(value1)){
		value1correcto = true;
	}else{
		value1correcto = false;
		mensajeerror = '<p>' + error1 + '</p>';
		$('.error').removeClass('errorVacio');
	}
	
	if (value2!="" && value2!=null && regexp.test(value2)){
		value2correcto = true;
	}else{
		value2correcto = false;
		mensajeerror += '<p>' + error2 + '</p>';
		$('.error').removeClass('errorVacio');
	}
	
	if(!value1correcto || !value2correcto){
		document.getElementById('errorEmail').innerHTML = mensajeerror;
		return false;
	}else{
		return true;
	}
}
	
// Funciona que valida los campos del formulario de la pagina 'Contacto'
function validaForm(regexp, email, errorEmail, campos, erroresCampos){
	var emailcorrecto = false;
	var camposcorrectos = true;

	var mensajeerror = '';

	if (email!="" && email!=null && regexp.test(email)){
		emailcorrecto = true;
	}else{
		emailcorrecto = false;
		mensajeerror = '<p>' + errorEmail + '</p>';
		$('.error').removeClass('errorVacio');
	}

    for(i=0;i<campos.length;++i){
		if (document.getElementById(campos[i]).value==null || document.getElementById(campos[i]).value==''){
			camposcorrectos = false;
			mensajeerror += '<p>' + erroresCampos[i] + '</p>';
			$('.error').removeClass('errorVacio');
		}
	}

	if(!emailcorrecto || !camposcorrectos){
	    document.getElementById('errorEmail').innerHTML = mensajeerror;
	    return false;
	}else{
		return true;
	}
}

// Funciona que valida los campos del formulario de la pagina CSV
function validaCSV(campos, erroresCampos){
	var camposcorrectos = true;

	var mensajeerror = '';

    for(i=0;i<campos.length;++i){
		if (document.getElementById(campos[i]).value==null || document.getElementById(campos[i]).value==''){
			camposcorrectos = false;
			mensajeerror += '<p>' + erroresCampos[i] + '</p>';
			$('.error').removeClass('errorVacio');
		}
	}

	if(!camposcorrectos){
	    document.getElementById('errorEmail').innerHTML = mensajeerror;
	    return false;
	}else{
		return true;
	}
}
	
// Carga los roles de wai-aria
function addARIARole(strID, strRole)
{
	// Find the element to add a role property to
	var objElement = document.getElementById(strID);
	if (objElement)
	{
		// Add the role property to the element
		objElement.setAttribute('role', strRole);
	}
}
		
// funcion general para cargar contenido por ajax pasandole la capa desde donde se hace la llamada para poder actualizar los datos tomando como referenica desde donde se hizo la llamada
function cargarContenido (pagina,parametros,tipoDatos,exito,fracaso,capa){			
			
	$.ajax({
		url: pagina,
		type: 'get',
		cache:false,
		async: true,
		ifModified : false,
		data: parametros,
		dataType: tipoDatos,
		timeout: 5000,
		success: function(resp){
			exito(resp,capa)
		}
	});
}